<template>
    <div class="d-flex justify-content-between mb-5">
        <div>
            <h1>{{$t('navigation.reservations')}}: {{item.name}}</h1>
            <div class="text-gray fs-15">#{{item.external_id}} - {{formatDate(item.start_time, 'overlongDate')}}</div>
        </div>
    </div>

    <div id="progress-bar" class="pt-4 mb-4" :style="'--steps: ' + reservationSteps.length">
        <div v-for="(text, step) in reservationSteps" :key="step" :class="{complete: step < item.step, pending: step >= item.step}">
            <div class="balloon" v-if="step < item.step">{{formatDate(reservationStepDates[step], 'balloonDate')}}<br><span>{{formatDate(reservationStepDates[step], 'time')}}</span></div>
            <i class="far" :class="{'fa-check default-color': step < item.step, 'fa-hourglass': step >= item.step}"></i>
            <div>
                <div v-html="text"></div>
                <div class="ps-2 position-relative float-end" style="translate: 65px -40px">
                    <span class="logo-tiny" :style="{backgroundImage: 'url(../../tinylogos/'+reservationLogos[step][0]+'.png)'}"></span>
                    <span class="logo-tiny ms-1" :style="{backgroundImage: 'url(../../tinylogos/'+reservationLogos[step][1]+'.png)'}"></span>
                </div>
            </div>
        </div>
    </div>

    <div class="d-flex justify-content-end action-bar">
        <button type="button" id="dropdownMenuButton2" class="btn btn-unidark btn-sec-overview ps-4" data-bs-toggle="dropdown" aria-expanded="false">
            {{$tc('overview.actions', 2)}} <i class="fa-light fa-chevron-down float-end pt-1 ms-4 me-1"></i>
        </button>
        <ul class="dropdown-menu dropdown-menu-end" aria-labelledby="dropdownMenuButton2">
            <li>
                <a class="dropdown-item" @click="sendReservationToPos">
                    <i class="fal fa-upload me-2"></i> Stuur reservering naar unTill
                </a>
                <a class="dropdown-item" @click="getReservationUsageFromPos">
                    <i class="fal fa-download me-2"></i> Haal verbruik op uit unTill
                </a>
                <a class="dropdown-item" @click="sendReservationUsage">
                    <i class="fal fa-upload me-2"></i> Stuur verbruik naar {{this.item.reservationSystem.name}}
                </a>
            </li>
        </ul>
    </div>
    <ul class="nav nav-tabs mb-5" id="myTab" role="tablist" style="font-size: 0.95rem !important">
        <li class="nav-item" role="presentation">
            <button class="nav-link active" id="info-1-tab" data-bs-toggle="tab" data-bs-target="#info-1" type="button" role="tab" aria-controls="info-1" aria-selected="true">{{$t('navigation.reservations')}}</button>
        </li>
        <li class="nav-item" role="presentation">
            <button class="nav-link" id="info-2-tab" data-bs-toggle="tab" data-bs-target="#info-2" type="button" role="tab" aria-controls="info-2">{{$tc('navigation.logs', 2)}}</button>
        </li>
    </ul>

    <div class="tab-content" id="myTabContent">
        <div class="tab-pane fade show active" id="info-1" role="tabpanel" aria-labelledby="info-1-tab">

            <h2><i class="far fa-desktop me-2 fs-15"></i>{{$t('daystate.information')}}</h2>
            <div class="row mt-2">
                <div class="col-2 fs-14 text-gray">{{$t('navigation.locations')}}</div>
                <div class="col-2 fs-14 text-gray">{{$t('overview.relation')}}</div>
                <div class="col-2 fs-14 text-gray">{{$t('overview.contact_person')}}</div>
                <div class="col-2 fs-14 text-gray">{{$t('reservation.event_date')}}</div>
                <div class="col-2 fs-14 text-gray">{{$t('reservation.amount_of_people')}}</div>
                <div class="col-2 fs-14 text-gray">{{$t('reservation.table_nr')}}</div>
            </div>
            <div class="row">
                <div class="col-2 fs-15">{{item.location.name}}</div>
                <div class="col-2 fs-15">{{item.relation_organisation}}</div>
                <div class="col-2 fs-15">{{item.relation_name}}</div>
                <div class="col-2">
                    <div class="fs-15">{{formatDate(item.date, 'longDate')}}</div>
                    <div class="fs-14 text-gray">{{formatDate(item.start_time, 'time')}}</div>
                </div>
                <div class="col-2 fs-15">{{item.persons}}</div>
                <div class="col-2 fs-15">{{item.pos_table_number}}</div>
            </div>

            <br>

            <div class="d-flex">
                <section>
                    <h2><i class="far fa-desktop me-2 fs-15"></i>{{$t('reservation.products_reserved')}}</h2>
                    <div class="row py-2 mt-1">
                        <div class="col-1 fs-14 text-gray">{{$t('overview.amount')}}</div>
                        <div class="col-3 fs-14 text-gray">{{$t('navigation.holdings')}}</div>
                        <div class="col-4 fs-14 text-gray">{{$t('overview.name')}}</div>
                        <div class="col-auto fs-14 text-gray pe-0" style="width: 18%">BTW / Per stuk</div>
                        <div class="col-auto fs-14 text-gray text-end" style="width: 15.3%">{{$t('daystate.subtotal')}}</div>
                    </div>
                    <div class="row border-top py-2" v-for="product in reservationProducts" :key="product.id">
                        <div class="col-1 fs-15">{{product.quantity}}</div>
                        <div class="col-3 fs-15">{{product.reservation_group.name}}</div>
                        <div class="col-4 fs-15">{{product.name}}</div>
                        <div class="col-2 fs-14 text-gray text-end">
                            <span style="float: left">{{product.vat_percentage ? `${product.vat_percentage}%` : ''}}</span>
                            {{formatCurrency(product.single_price, true)}}
                        </div>
                        <div class="col-2 fs-15 text-end">{{formatCurrency(product.price)}}</div>
                    </div>
                    <div class="row border-top pt-2">
                        <div class="col-9 total-row">{{$t('daystate.total')}}</div>
                        <div class="col-3 text-end total-row">{{formatCurrency(reservationProductsTotal, true)}}</div>
                    </div>
                </section>
                
                <section>
                    <template v-if="item.step > 2">
                        <h2><i class="far fa-desktop me-2 fs-15"></i>{{$t('reservation.products_sold')}}</h2>
                        <div class="row py-2 mt-1">
                            <div class="col-auto fs-14 text-gray" style="width: 13.6%">{{$t('overview.amount')}}</div>
                            <div class="col-auto fs-14 text-gray" style="width: 23%">{{$t('navigation.holdings')}}</div>
                            <div class="col-auto fs-14 text-gray" style="width: 30%">{{$t('overview.name')}}</div>
                            <div class="col-auto fs-14 text-gray pe-0" style="width: 18%">BTW / Per stuk</div>
                            <div class="col-auto fs-14 text-gray text-end" style="width: 15.3%">{{$t('daystate.subtotal')}}</div>
                        </div>
                        <div class="row border-top py-2" v-for="product in posProducts" :key="product.id">
                            <div class="col-auto fs-15" style="width: 13.6%">{{product.usage}} &nbsp;
                                <span v-if="product.usage < product.quantity" class="text-danger"><i class="fa fa-chevron-down me-1"></i>{{product.quantity - product.usage}}</span>
                                <span v-else-if="product.usage > product.quantity" class="text-success"><i class="fa fa-chevron-up me-1"></i>{{product.usage - product.quantity}}</span>
                            </div>
                            <div class="col-auto fs-15" style="width: 23%">{{product.reservation_group.name}}</div>
                            <div class="col-auto fs-15" style="width: 30%">{{product.name}}</div>
                            <div class="col-2 fs-14 text-gray text-end">
                                <span style="float: left">{{product.vat_percentage ? `${product.vat_percentage}%` : ''}}</span>
                                {{formatCurrency(product.single_price, true)}}
                            </div>
                            <div class="col-2 fs-15 text-end">{{formatCurrency(product.usage * product.single_price)}}</div>
                        </div>
                        <div class="row border-top pt-2">
                            <div class="col-9 total-row">{{$t('daystate.total')}}</div>
                            <div class="col-3 text-end total-row">{{formatCurrency(posProductsTotal, true)}}</div>
                        </div>
                    </template>
                </section>
            </div>

            <FooterButtons @prev="prev" @save="save" :disabled="isSaving" />
        </div>

        <div class="tab-pane fade" id="info-2" role="tabpanel" aria-labelledby="info-2-tab">
            Logs zijn nog niet beschikbaar
        </div>
    </div>
</template>

<script>
    import resService from '@/services/ReservationService';
    import FooterButtons from '@/components/FooterButtons.vue';
    export default {
        name: 'ReservationDetail',
        components: {
            FooterButtons
        },
        data() {
            return {
                item: {
                    "current_date": null,
                    'import_sync' : null,
                    'export_sync': null,
                    'total_payment' : "",
                    'total_turnover' : "",
                    'location_id': null,
                    "name": "",
                    "consolidated_income": {"total": 0, "lines": []},
                    "consolidated_revenue": {"total": 0, "lines": []},
                    "location": {
                        "name": ""
                    },
                    "reservationSystem": {
                        "name": ""
                    },
                },
                locations: [],
                reservationSteps: ['' , '', '', ''],
                reservationLogos: ['' , '', '', ''],
                reservationStepDates: ['' , '', '', ''], 
                statuses: [
                    {"name": "confirmed", "step": 1},
                    {"name": "active_warning", "step": 1},
                    {"name": "active_error", "step": 1},
                    {"name": "active", "step": 2},
                    {"name": "usage_warning", "step": 2},
                    {"name": "usage_error", "step": 2},
                    {"name": "usage", "step": 3},
                    {"name": "ok", "step": 4},
                ],
                reservationProducts: [],
                reservationProductsTotal: 0,
                posProducts: [],
                posProductsTotal: 0,
                isSaving: false,
                isImporting: false,
                isExporting: false
            }
        },
        methods: {
            save() {
                this.$router.push({'name': "Reserveringen"});
            },
            prev() {
                this.$router.push({'name': "Reserveringen"});
            },
            importData(){
                //
            },
            show(id){
                resService.show(id).then(response => {
                    this.isImporting = false;
                    this.isExporting = false;
                    this.item = response.data;
                    // this.item.name = this.item.location.name;
                    this.item.step = this.statuses.find(s => s.name === this.item.status).step;
                    //find the current step based on status
                    
                    this.reservationProductsTotal = 0;
                    this.posProductsTotal = 0;
                    //foreach reservation product add to reservationProducts
                    this.item.reservation_product.forEach(product => {
                        if(product.quantity)
                        {    
                            this.reservationProducts.push(product);
                            this.reservationProductsTotal += parseFloat(product.price);
                            console.log(product.name, this.reservationProductsTotal);
                        }
                        this.posProducts.push(product);
                        this.posProductsTotal += product.single_price * product.usage;
                    });
                    
                    this.reservationSteps = [
                        this.$t('reservation.step_1', [this.item.reservationSystem.name]),
                        this.$t('reservation.step_2', ["unTill"]),
                        this.$t('reservation.step_3', ["unTill"]),
                        this.$t('reservation.step_4', [this.item.reservationSystem.name]),
                    ];
                    this.reservationLogos = [
                        [this.item.reservationSystem.name.toLowerCase(), 'arrow'],
                        ['arrow', 'untill'],
                        ['untill', 'arrow'],
                        ['arrow', this.item.reservationSystem.name.toLowerCase()],
                    ];
                    this.reservationStepDates = [
                        this.item.reservation_import_sync,
                        this.item.pos_export_sync,
                        this.item.pos_import_sync,
                        this.item.reservation_export_sync,
                    ];
                    console.log(this.reservationProductsTotal);
                    // this.totalRevenue = this.item.consolidated_revenue.total;
                });
            }
        },
        mounted(){
            const locationId = this.$route.params.id;
            this.show(locationId);
        }
    }

</script>
<style scoped>
.col2p5{
    width: 20.8%;
    flex: 0 0 auto
}
section{
    width: 50%
}
section:first-child{
    padding-right: calc(5% + 1rem)
}
section:last-child{
    padding-left: calc(5% + 1rem)
}
.action-bar{
    position: absolute;
    width: 50%;
    right: 3rem;
}
.border-top{
    border-top: 1px solid #bfbfbf;
}
.total-row{
    font-size: 17px;
    font-weight: 700;
    color: var(--uni-dark)
}
</style>
